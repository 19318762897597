export // 第一个参数是需要进行防抖处理的函数，第二个参数是延迟时间，默认为1秒钟
function debounce(fn: any, delay = 1000) {
  // 实现防抖函数的核心是使用setTimeout
  // time变量用于保存setTimeout返回的Id
  let time: any = null;

  // 将回调接收的参数保存到args数组中
  const _debounce = (...args: any) => {
    // 如果time不为0，也就是说有定时器存在，将该定时器清除
    if (time !== null) {
      clearTimeout(time);
    }

    time = setTimeout(() => {
      fn();
    }, delay);
  };

  // 防抖函数会返回另一个函数，该函数才是真正被调用的函数
  return _debounce;
}
